<template>
  <v-navigation-drawer
    v-if="logged"
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== '#d35400'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <v-img src="@/assets/logo.png" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="text-h4"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        />
        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'Dashboard',
          to: '/',
          module: 'dashboard',
        },
        {
          icon: 'mdi-account-supervisor',
          title: 'Revendedoras',
          to: '/pages/clientes',
          module: 'cliente',
        },
        {
          icon: 'mdi-chart-pie',
          title: 'Pedidos (Esc. Virtual)',
          to: '/pages/pedidos/portal',
          module: 'pedido.portal',
        },
        {
          icon: 'mdi-finance',
          title: 'Pedidos (Captura)',
          to: '/pages/pedidos/captura',
          module: 'pedido.captura',
        },
        {
          icon: 'mdi-map-marker',
          title: 'Frete',
          to: '/pages/configurador-frete',
          module: 'frete',
        },
        {
          icon: 'mdi-google-my-business',
          title: 'Centros de Distribuição',
          to: '/pages/centro-distribuicao',
          module: 'centro-distribuicao',
        },
        // {
        //   icon: 'mdi-currency-usd',
        //   title: 'Acerto Virtual',
        //   to: '/pages/acerto-virtual',
        //   module: 'acertovirtual',
        // },
        {
          icon: 'mdi-currency-usd-off',
          title: 'Inadimplentes',
          to: '/pages/inadimplentes',
          module: 'clientes-devedores',
        },
        {
          icon: 'mdi-file-document-edit',
          title: 'Tipos de Contrato',
          to: '/pages/tiposcontrato/',
          module: 'tiposcontrato',
        },
        {
          icon: 'mdi-text-box-check',
          title: 'Contratos',
          to: '/pages/contratos/',
          module: 'contratos',
        },
        {
          icon: 'mdi-ticket',
          title: 'Cupom - Solicitações',
          to: '/pages/cupom/solicitacoes',
          module: 'cupom',
        },
        {
          icon: 'mdi-cash-100',
          title: 'Crédito - Solicitações',
          to: '/pages/credito/solicitacoes',
          module: 'credito',
        },
        {
          icon: 'mdi-ballot',
          title: 'Produtos',
          group: '/pages',
          module: 'produto',
          children: [
            {
              icon: 'mdi-ballot',
              title: 'Listagem',
              to: 'produtos',
              module: 'produto',

            },
            {
              icon: 'mdi-plus',
              title: 'Novo',
              to: 'produtos/create',
              module: 'produto',

            },
            {
              icon: 'mdi-plus',
              title: 'Novo kit',
              to: 'kit',
              module: 'produto',

            },
          ],
        },
        {
          icon: 'mdi-ballot',
          title: 'Grade de cor',
          to: '/pages/grade/cores',
          module: 'cor',
        },
        {
          icon: 'mdi-ballot',
          title: 'Grade de tamanho',
          to: '/pages/grade/tamanhos',
          module: 'tamanho',
        },
        {
          icon: 'mdi-account',
          title: 'Minha Conta',
          to: '/pages/user',
        },
        {
          icon: 'mdi-account-multiple',
          title: 'Usuários',
          to: '/pages/usuarios',
          module: 'user',
        },
        {
          icon: 'mdi-account-check',
          title: 'Perfis',
          to: '/pages/perfil',
          module: 'perfil',
        },
        {
          icon: 'mdi-video',
          title: 'EAD - Videos',
          group: '/pages/ead',
          module: 'ead',
          children: [
            {
              icon: 'mdi-video',
              title: 'EAD - Videos',
              to: 'videos',
              module: 'ead',
            },
            {
              icon: 'mdi-video',
              title: 'EAD - Categoria',
              to: 'categorias',
              module: 'ead',
            },
          ],
        },
        {
          icon: 'mdi-image-multiple',
          title: 'Banners',
          to: '/pages/banners',
          module: 'banners',
        },
        {
          icon: 'mdi-link',
          title: 'Links Úteis',
          to: '/pages/linksuteis',
          module: 'linksuteis',
        },
        {
          icon: 'mdi-account-supervisor',
          title: 'Promotores',
          to: '/promotores',
          module: 'pagamento-do-promotor',
        },
        {
          icon: 'mdi-view-dashboard',
          title: 'Dashboard do Promotor',
          to: '/promotor/dashboard',
          module: 'dashboard-do-promotor',
        },
        {
          icon: 'mdi-google-controller',
          title: 'Gameficação - Níveis',
          to: '/pages/gamefication/levels',
          module: 'gamefication.level',
        },
        {
          icon: 'mdi-logout-variant',
          title: 'Logout',
          to: '/logout',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        if (!this.$store.getters.logged) {
          return []
        }
        const modulos = this.$store.getters.user.roles
        return this.items
          .filter(i => {
            return !i.module || modulos.indexOf(i.module) > -1
          })
          .map(this.mapItem)
      },
      logged () {
        return this.$route.name !== 'Login'
      },
      profile () {
        return {
          avatar: true,
          title: 'Gestor Styllus',
        }
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: item.title,
        }
      },
    },
  }
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
        margin-right: 24px
        margin-left: 12px !important

        +rtl()
        margin-left: 24px
        margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
        padding-left: 8px

        +rtl()
        padding-right: 8px

      .v-list-group__header
        +ltr()
        padding-right: 0

        +rtl()
        padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
          margin-right: 8px

          +rtl()
          margin-left: 8px
</style>
